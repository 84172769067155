.shortlisted {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #31bf6d;
  background: rgba(49, 191, 109, 0.22);
  border: 1px solid #31bf6d;
  border-radius: 3px;
  padding: 5px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 84px;
}

.applied {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #1B7EB3;
  background: rgba(27, 126, 179, 0.2);
  border: 1px solid #1B7EB3;
  border-radius: 3px;
  padding: 5px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 84px;
}

.shortisted {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #F09819;
  background: rgba(240, 152, 25, 0.2);
  border: 1px solid #F09819;
  border-radius: 3px;
  padding: 5px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 84px;
}

.selected {
  background: rgba(161, 98, 187, 0.22);
  border: 1px solid #a162bb;
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #a162bb;
  padding: 5px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 84px;
}

.offered {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #00bc8f;
  background: rgba(0, 188, 143, 0.22);
  border: 1px solid #00bc8f;
  border-radius: 3px;
  padding: 5px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 84px;
}

.offerAccepted {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #00bc8f;
  background: rgba(0, 188, 143, 0.22);
  border: 1px solid #00bc8f;
  border-radius: 3px;
  padding: 5px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 84px;
}

.offerRejected {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #ff6f60;
  background: rgba(255, 111, 96, 0.22);
  border: 1px solid #ff6f60;
  border-radius: 3px;
  padding: 5px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 84px;
}

.onHold {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #ffac27;
  background: #fef0db;
  border: 1px solid #ffac27;
  border-radius: 3px;
  padding: 5px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 84px;
}

.joined {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #1687ef;
  background: rgba(22, 135, 239, 0.2);
  border: 1px solid #1687ef;
  border-radius: 3px;
  padding: 5px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 84px;
}

.rejected {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #ff6f60;
  background: rgba(255, 111, 96, 0.22);
  border: 1px solid #ff6f60;
  border-radius: 3px;
  padding: 5px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 84px;
}

.new {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  background: #9a14d2;
  border: 1px solid #9a14d2;
  border-radius: 3px;
  padding: 5px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 84px;
}
